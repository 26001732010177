const columns = 12

const breakpoints = {
  xs: { width: '0rem', gutter: 16 },
  sm: { width: '36rem', gutter: 24 },
  md: { width: '48rem', gutter: 32 },
  lg: { width: '62rem', gutter: 32 },
  xg: { width: '80rem', gutter: 32 },
}

const grid = { columns, breakpoints }

export default grid
