import React from 'react'
import { SmallText } from '@/components/UI/Typography/Typography'
import { Wrapper } from './Button.css'

const Button = ({ children, ...props }) => (
  <Wrapper {...props}>
    <SmallText>{children}</SmallText>
  </Wrapper>
)

Button.defaultProps = {
  appearance: 'maastricht',
}

export default Button
