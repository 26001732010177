import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import { SmallText } from '@/components/UI/Typography/Typography'

export const Wrapper = styled.div`
  color: ${p => p.theme.colors[p.color || 'maastricht']};
  transition: 0.5s all;
  top: 0;
  opacity: ${p => (p.selected ? 1 : 0)};
`

export const Bottom = styled.div`
  color: currentColor;
  ${above('md')`
    display: flex;
    align-items: center;
  `}
`

export const Separator = styled.div`
  height: 1.75rem;
  ${above('md')`
    width: 1px;
    background: ${p => p.theme.colors.gunmetal};
    height: 1.75rem;
    margin: 0.5rem 1.5rem;
  `}
`

export const Content = styled(SmallText)`
  color: ${p => p.theme.colors.maastricht};
  margin: 1.75rem 0 2rem;
  ${above('md')`
    ${getTypeStyle('text')};
  `};
`

export const Handler = styled.button`
  display: flex;
  align-items: center;
  color: currentColor;
`

export const InterviewText = styled(SmallText)`
  margin-left: 0.75rem;
`
