import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Testimonial from '@/components/UI/Testimonial/Testimonial'
import { Wrapper, Button, Dot } from './TestimonialGroup.css'

const TestimonialGroup = ({ testimonials, color, ...props }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const length = testimonials?.length || 0

  const settings = {
    customPaging(index) {
      return (
        <Button type='button'>
          <Dot color={color} active={index === slideIndex}>
            {index + 1}
          </Dot>
        </Button>
      )
    },
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  if (length === 0) {
    return null
  }

  if (length === 1) {
    return testimonials.map(testimonial => (
      <Testimonial
        selected
        key={testimonial}
        {...testimonial}
        color={color}
        src={testimonial?.image}
      />
    ))
  }

  return (
    <Wrapper {...props}>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={testimonial}
            {...testimonial}
            color={color}
            src={testimonial?.image}
            selected={index === slideIndex}
            tabIndex={index === slideIndex ? 0 : -1}
          />
        ))}
      </Slider>
    </Wrapper>
  )
}

export default TestimonialGroup
