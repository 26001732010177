import React from 'react'

const Facebook = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M15.208 12.583h-2.041v7.584h-2.771v-7.584H8.5v-2.61h1.896V8.447c0-1.149.21-2.01.632-2.585C11.449 5.287 12.243 5 13.41 5h2.041v2.61h-1.264c-.453 0-.737.082-.85.246-.114.164-.17.443-.17.837v1.28H15.5l-.292 2.61z'
    />
  </svg>
)

Facebook.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Facebook
