import React from 'react'
import { Wrapper } from './Youtube.css'

const Youtube = ({ id, ...props }) => (
  <Wrapper {...props}>
    <iframe
      title='video'
      src={`https://youtube.com/embed/${id}?autoplay=1`}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    />
  </Wrapper>
)

export default Youtube
