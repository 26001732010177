const getDate = date => {
  if (!date) {
    return null
  }

  const instanceDate = new Date(date)
  const day = instanceDate.getDate()
  const month = instanceDate.getMonth() + 1
  let formatedDay = day.toString()
  let formatedMonth = month.toString()

  if (formatedDay.length < 2) {
    formatedDay = `0${formatedDay}`
  }
  if (formatedMonth.length < 2) {
    formatedMonth = `0${formatedMonth}`
  }

  return `${formatedDay}.${formatedMonth}`
}

export default getDate
