import React from 'react'

const Download = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill='none'
      stroke={color}
      fillRule='evenodd'
      d='M8.0005 13.9l7.4995-.00098h.7353c2.0798 0 3.76176-1.65609 3.76176-3.6939 0-1.91427-1.4907-3.51005-3.42277-3.67913l-.48408-.04236-.09635-.47628C15.60203 4.0704 13.86286 2.65 11.82353 2.65c-2.03932 0-3.7785 1.42041-4.17033 3.35735l-.09635.47628-.48408.04236C5.1407 6.69507 3.65 8.29085 3.65 10.20513c0 2.0378 1.68196 3.69389 3.76285 3.6939L8.0005 13.9zM11.8125 10.40446v10.38062M15.207 17.24091l-3.391 3.36607-3.391-3.36607'
    />
  </svg>
)

Download.defaultProps = {
  size: 24,
  color: 'currentColor',
  strokeWidth: 1.15,
  style: { display: 'block' },
}

export default Download
