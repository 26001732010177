const sansStack =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const serifStack =
  'Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif'

const fonts = {
  roboto: `"Roboto", ${sansStack}`,
  text: `"Tiempos Text", ${serifStack}`,
  headline: `"Tiempos Headline", ${serifStack}`,
}

const type = {
  fonts,
  scale: {
    label: { fontSize: 12, lineHeight: 16, letterSpacing: 0.5 },
    footnote: { fontSize: 14, lineHeight: 16, letterSpacing: 0.5 },
    smallText: { fontSize: 16, lineHeight: 22, letterSpacing: 0.0 },
    blogText: { fontSize: 18, lineHeight: 24, letterSpacing: 0.2 },
    text: { fontSize: 20, lineHeight: 28, letterSpacing: 0.0 },
    caption: { fontSize: 24, lineHeight: 30, letterSpacing: 0.0 },
    subtitle: { fontSize: 28, lineHeight: 34, letterSpacing: 0.2 },
    mobileTitle: { fontSize: 40, lineHeight: 46, letterSpacing: 0.1 },
    title: { fontSize: 48, lineHeight: 56, letterSpacing: 0.2 },
    mainTitle: { fontSize: 64, lineHeight: 72, letterSpacing: 0.1 },
  },
}

export default type
