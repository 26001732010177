import React from 'react'
import { ThemeProvider as Provider } from 'styled-components'

import colors from './colors'
import grid from './grid'
import transitions from './transitions'
import type from './type'

const theme = {
  colors,
  grid,
  transitions,
  type,
}

export const ThemeProvider = ({ children, props }) => (
  <Provider theme={theme} {...props}>
    {children}
  </Provider>
)

export default theme
