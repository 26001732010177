import styled from 'styled-components'
import above from '@/utils/above'
import UnstyledLink from '@/components/UI/Link/Link'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -0.5rem;

  ${above('sm')`
    padding-left: 1rem;
  `}
  ${above('xg')`
    margin: -0.25rem;
  `}
`

export const Link = styled(UnstyledLink)`
  color: ${p => p.theme.colors.gray};
  padding: 0.5rem;
  ${above('xg')`
    padding: 0.25rem;
  `}
  &:hover {
    color: ${p => p.theme.colors.snow};
  }
`

export const Circle = styled.div`
  border-radius: 50%;
  background: ${p => p.theme.colors.white}06;
  padding: 0.25rem;
  ${Link}:hover & {
    background: ${p => p.theme.colors.white}16;
  }
`
