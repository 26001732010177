import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import getReadableColor from '@/utils/getReadableColor'
import Image from '@/components/UI/Image/Image'

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 10;
  * {
    outline: none;
  }
  img {
    user-drag: none;
  }
  ${above('md')`
    border-radius: 0.125rem;
  `}
`

export const StyledImage = styled(Image).attrs({ objectFit: 'cover' })`
  height: 100%;
`

export const Bottom = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 4px;
  width: 100%;
  background: linear-gradient(180deg, #00000000 0%, #0000007d 100%);
  color: ${p => p.theme.colors.white};
  padding: 1.75rem 1.25rem 1.25rem;
  min-height: 5.5rem;
  ${above('md')`
    padding: 1.5rem 2rem;
  `}
`

export const ButtonPrev = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  background: ${p => p.theme.colors.white};
  color: ${p => p.theme.colors.maastricht};
  transition: 0.2s all;
  z-index: 20;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 1rem;

  ${above('md')`
    left: unset;
    top: unset;
    transform: none;
    right: 5.5rem;
    bottom: 1.5rem;
  `}

  @media not all and (pointer: coarse) {
    &:hover,
    &:active {
      background: ${p => p.theme.colors[p.color]};
      color: ${p => p.theme.colors[getReadableColor(p.color)]};
    }
  }
`

export const ButtonNext = styled(ButtonPrev)`
  left: unset;
  right: 1rem;
  ${above('md')`
    right: 2rem;
  `}
`

export const Index = styled.div`
  ${getTypeStyle('footnote')};
`

export const Title = styled.div`
  ${getTypeStyle('footnote')};
  opacity: 0.8;
  padding-top: 0.2rem;
`
