import React from 'react'
import styled from 'styled-components'

const getTransform = direction => {
  if (direction === 'down') return `rotate(90deg)`
  if (direction === 'left') return 'rotate(180deg)'
  if (direction === 'up') return 'rotate(270deg)'
  return '0'
}

const Wrapper = styled.svg`
  transform: ${p => getTransform(p.direction, p.size)};
  display: block;
`

const Chevron = ({ size, color, strokeWidth, direction, ...props }) => (
  <Wrapper
    size={size}
    direction={direction}
    width={size}
    height={size}
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='none'
      stroke={color}
      strokeWidth={strokeWidth}
      d='M12.00107 4.93l7.07107 7.07107-7.07107 7.07107'
    />
  </Wrapper>
)

Chevron.defaultProps = {
  size: 24,
  strokeWidth: 2.4,
  color: 'currentColor',
}

export default Chevron
