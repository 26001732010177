import styled from 'styled-components'
import above from '@/utils/above'
import Image from '@/components/UI/Image/Image'
import Link from '@/components/UI/Link/Link'
import Group from '@/components/UI/Group/Group'
import { Text, SmallText } from '@/components/UI/Typography/Typography'

export const Wrapper = styled.div`
  border-radius: 0.125rem;
  overflow: hidden;
  background: ${p => p.theme.colors.white};
`

export const Main = styled.div`
  padding: 1.5rem 1.5rem 1.25rem;
`

export const StyledImage = styled(Image)`
  display: block;
  border-radius: 0.125rem;
`

export const Name = styled(Text)`
  margin-bottom: 0.125rem;
`

export const Position = styled(SmallText)`
  color: ${p => p.theme.colors.gray};
  min-height: 44px;
`

export const Bottom = styled(Group)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  flex-wrap: wrap;
`

export const Social = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -0.25rem;
  ${above('xg')`
    margin: -0.25rem;
  `}
`

export const SocialLink = styled(Link)`
  color: ${p => p.theme.colors.gray};
  padding: 0.25rem;
  ${above('xg')`
    padding: 0.25rem;
  `}
  &:hover,
  &:focus,
  &:active {
    color: ${p => p.theme.colors.slate};
  }
`

export const SocialCircle = styled.div`
  border-radius: 50%;
  background: ${p => p.theme.colors.gunmetal}4C;
  padding: 0.25rem;
  ${SocialLink}:hover & {
    background: ${p => p.theme.colors.gunmetal}66;
  }
`

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  color: ${p => p.theme.colors.blue};
  border: 1px solid ${p => p.theme.colors.blue};
  border-radius: 0.125rem;
  svg {
    margin: 0 0.25rem 1px 0;
  }
  &:hover,
  &:focus,
  &:active {
    background: ${p => p.theme.colors.blue};
    color: ${p => p.theme.colors.white};
  }
`
