import React, { useState } from 'react'
import { Wrapper, Field, FieldWrapper, Absolute, LabelText } from './Input.css'

const Input = ({
  id,
  label,
  error,
  value,
  onChange,
  defaultValue,
  icon,
  errorColor,
  color,
  as,
  isForm,
  ...props
}) => {
  const [state, setState] = useState(value || defaultValue)
  const controlled = typeof onChange === 'function'
  const fieldValue = controlled ? value : state
  const onChangeFunction = controlled ? onChange : e => setState(e.target.value)

  // false negative
  /* eslint-disable jsx-a11y/label-has-for */
  return (
    <Wrapper htmlFor={id}>
      <LabelText weight={500} hide={!label}>
        {label}
      </LabelText>
      <FieldWrapper isForm={isForm} color={color}>
        <Field
          as={as}
          icon={icon}
          id={id}
          value={fieldValue}
          onChange={onChangeFunction}
          color={color}
          {...props}
        />
        {icon && <Absolute type={as}>{icon}</Absolute>}
      </FieldWrapper>
      <LabelText xs={0} weight={500} color={errorColor || 'darkOrange'}>
        {error}
      </LabelText>
    </Wrapper>
  )
}

export default Input
