import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  max-width: 22rem;
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  overflow: hidden;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 100%;
  margin-right: 1rem;
  flex-shrink: 0;
  & > img,
  & > div {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${p =>
    p.size === 'small' &&
    `
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.75rem;
  `}
`
