import styled, { css } from 'styled-components'
import above from '@/utils/above'

/* this component creates a Grid which has negative margins and              */
/* it’s direct children has positive margin to keep its spacing              */
/* there is an optional prop that creates vertical spacing inside            */

const horizontalGutter = gutter => `
  margin: 0 -${gutter / 2}px;
  max-width: calc(100% + ${gutter}px);
  & > * {
    padding: 0 ${gutter / 2}px;
  }
`

const verticalGutter = gutter => `
  margin: ${gutter / -2}px;
  & > * {
    padding: ${gutter / 2}px;
  }
`

const Grid = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  ${p =>
    !p['vertical-gutter'] &&
    css`
      ${horizontalGutter(p.theme.grid.breakpoints.xs.gutter)};
      ${above('sm')`
      ${horizontalGutter(p.theme.grid.breakpoints.sm.gutter)};
    `}
      ${above('md')`
      ${horizontalGutter(p.theme.grid.breakpoints.md.gutter)};
    `}
    `}
  ${p =>
    p['vertical-gutter'] &&
    css`
      ${verticalGutter(p.theme.grid.breakpoints.xs.gutter)};
      ${above('sm')`
      ${verticalGutter(p.theme.grid.breakpoints.sm.gutter)};
    `}
      ${above('md')`
      ${verticalGutter(p.theme.grid.breakpoints.md.gutter)};
    `}
    `}
`

export default Grid
