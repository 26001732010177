import styled from 'styled-components'
import Link from '@/components/UI/Link/Link'
import { SmallText } from '@/components/UI/Typography/Typography'

export const Wrapper = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${p => (p.color ? p.theme.colors[p.color] : 'currentColor')};
  align-items: center;
  svg {
    transition: 0.2s all;
  }
  &:hover {
    svg {
      ${p =>
        p.reverse
          ? `transform: translateX(-0.25em) rotate(180deg);`
          : `transform: translateX(0.25em);`}
    }
  }
`

export const Text = styled(SmallText)`
  margin-${p => (p.reverse ? 'right' : 'left')}: 0.25rem;
`
