import styled, { css } from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import Link from '@/components/UI/Link/Link'
import BaseGrid from '@/components/UI/Grid/Grid'
import BaseImage from '@/components/UI/Image/Image'
import {
  Title as BaseTitle,
  Label,
  Caption,
  SmallText,
} from '@/components/UI/Typography/Typography'

export const Wrapper = styled(Link)`
  display: block;
  color: ${p => p.theme.colors[p.color] || p.color};
  background: ${p => p.theme.colors[p.background] || p.background};
  border-radius: 0.125rem;
  box-shadow: 0 0.25rem 2.5rem 0 ${p => p.theme.colors.maastricht}14;
  overflow: hidden;
  position: relative;
  transition: all ${p => p.theme.transitions.ease()};
  display: flex;
  flex: 1;
  height: 100%;

  @media not all and (pointer: coarse) {
    &:hover,
    &:active {
      background: ${p => p.theme.colors.yellow};
      color: ${p => p.theme.colors.white};
      ${SmallText},${Label} {
        color: ${p => p.theme.colors.white};
      }
    }
  }
`

export const Grid = styled(BaseGrid)`
  align-items: center;
  flex-direction: ${({ fullWidth }) => (fullWidth ? 'row' : 'column')};

  @media (max-width: 575px) {
    flex-direction: column;
  }
  ${p =>
    p.imagePosition === 'left' &&
    css`
      ${above('md')`
      flex-direction: row-reverse;
    `}
    `}
`

export const Inner = styled.div`
  ${p =>
    p.imagePosition &&
    css`
      ${above('md')`
      width: ${getColumnSize(5)};
    `}
      ${above('lg')`
      width: ${getColumnSize(4)};
    `}
    `}
`

export const Main = styled.div`
  padding: 1.5rem 1.25rem;
  ${above('md')`
    padding: 1.5rem 2rem;
  `}
  ${p =>
    p.imagePosition &&
    css`
      ${above('md')`
      padding-${p.imagePosition}: 0 !important;
    `}
    `}
`

export const CardDate = styled(BaseTitle)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 2rem;
  max-width: fit-content;
  display: inline-block;
  margin-right: 10px;
`

export const Kind = styled(Label)`
  margin-bottom: 0.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${p => p.theme.colors.gray};
  transition: color ${p => p.theme.transitions.ease()};
`

export const Title = styled(Caption)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  max-width: 260px;
  ${above('md')`
    margin-bottom: 2rem;
    max-width: unset;
  `}
`

export const Location = styled(SmallText)`
  color: ${p => p.theme.colors.gray};
  transition: color ${p => p.theme.transitions.ease()};
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
  ${p =>
    p.imagePosition
      ? css`
    max-height: 10.25rem;
    ${above('sm')`
      max-height: 12rem;
    `}
    ${above('md')`
      max-height: 100% !important;
      position: absolute;
      padding-${p.imagePosition}: 0;
      height: 100%;
      ${p.imagePosition}: 0;
      width: ${getColumnSize(7)};
    `}
    ${above('lg')`
      width: ${getColumnSize(8)};
    `}
  `
      : css`
    max-height: 10.25rem;
    ${above('sm')`
      max-height: 12rem;
    `}
    ${above('md')`
      max-height: 15rem;
    `}
    ${above('lg')`
      max-height: 17.75rem;
    `}
  `}
`

export const Image = styled(BaseImage)`
  display: block;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
`
