import React from 'react'
import styled from 'styled-components'

const getTransform = direction => {
  if (direction === 'down') return `rotate(90deg)`
  if (direction === 'left') return 'rotate(180deg)'
  if (direction === 'up') return 'rotate(270deg)'
  return '0'
}

const Wrapper = styled.svg`
  transform: ${p => getTransform(p.direction, p.size)};
  display: block;
  path {
    fill: ${p =>
      p.color ? p.theme.colors[p.color] || p.color : 'currentColor'};
  }
`

const Scissors = ({ size, color, ...p }) => (
  <Wrapper width={size} color={color} height={size} viewBox='0 0 24 24' {...p}>
    <path
      fillRule='nonzero'
      d='M10.637 9.334l-.058-.166a2.34 2.34 0 0 0 .077-.357c.017-.126.025-.257.025-.395 0-.9-.318-1.666-.959-2.307a3.14 3.14 0 0 0-2.306-.959c-.9 0-1.666.318-2.307.96a3.14 3.14 0 0 0-.959 2.306c0 .912.318 1.68.959 2.315.64.635 1.406.95 2.307.95.426 0 .825-.077 1.198-.232.376-.156.707-.367.995-.632h.204l1.699 1.573v.22l-1.7 1.572H9.61a3.358 3.358 0 0 0-.995-.63 3.091 3.091 0 0 0-1.198-.233c-.9 0-1.666.318-2.307.959a3.14 3.14 0 0 0-.959 2.306c0 .9.318 1.666.96 2.307.64.64 1.406.959 2.306.959.9 0 1.666-.318 2.306-.96.64-.64.96-1.406.96-2.306a2.79 2.79 0 0 0-.104-.755l.059-.163 2.639-1.843.175.002 3.81 2.836c.973.687 1.737.758 2.327.244l.831-.723-9.782-6.848zm-3.221.912c-.498 0-.928-.178-1.281-.532a1.772 1.772 0 0 1-.532-1.298c0-.498.179-.928.532-1.281a1.751 1.751 0 0 1 1.28-.532c.498 0 .928.179 1.281.532.354.353.532.783.532 1.28 0 .51-.178.946-.532 1.3a1.751 1.751 0 0 1-1.28.531zm0 8.151c-.498 0-.928-.179-1.281-.532a1.751 1.751 0 0 1-.532-1.28c0-.498.179-.928.532-1.281a1.751 1.751 0 0 1 1.28-.532c.498 0 .928.178 1.281.532.354.353.532.783.532 1.28 0 .498-.178.928-.532 1.281a1.751 1.751 0 0 1-1.28.532zM19.589 8.105c-.591-.51-1.355-.44-2.328.235l-2.788 1.955 1.924 1.335 4.021-2.811-.83-.714z'
    />
  </Wrapper>
)

Scissors.defaultProps = {
  size: 24,
}

export default Scissors
