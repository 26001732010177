import React from 'react'

const PlayCircle = ({
  size,
  color,
  background = '#C0C7D1',
  backgroundOpacity = 0.2,
  ...props
}) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={background}
      opacity={backgroundOpacity}
      d='M12 24C5.37258 24 0 18.62742 0 12S5.37258 0 12 0s12 5.37258 12 12-5.37258 12-12 12zM9.05263 7.78947v8.63158l7.57895-4.21052-7.57895-4.42106z'
    />
    <path fill={color} d='M9.05263 7.78947v8.63158l7.57895-4.21052z' />
  </svg>
)

PlayCircle.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default PlayCircle
