import styled, { css } from 'styled-components'

import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'

const Base = styled.p`
  ${p => p.font && `font-family: ${p.theme.type.fonts[p.font]};`}
  ${p => p.color && `color: ${p.theme.colors[p.color]};`}
  ${p => p.weight && `font-weight: ${p.weight};`}
  ${p => p.uppercase && `text-transform: uppercase;`}
`

export const Label = styled(Base)`
  ${getTypeStyle('label')};
`

export const Footnote = styled(Base)`
  ${getTypeStyle('footnote')};
`

export const SmallText = styled(Base)`
  ${getTypeStyle('smallText')};
`

export const BlogText = styled(Base)`
  ${getTypeStyle('blogText')};
  ${p =>
    p.main &&
    css`
      font-family: ${p.theme.type.fonts.headline};
      font-weight: 500;
    `}
`

export const Text = styled(Base)`
  ${getTypeStyle('text')};
`

export const Caption = styled(Base)`
  ${getTypeStyle('caption')};
`

export const Subtitle = styled(Base)`
  ${getTypeStyle('subtitle')};
`

export const MobileTitle = styled(Base)`
  ${getTypeStyle('mobileTitle')};
`

export const Title = styled(Base)`
  ${getTypeStyle('title')};
`

export const MainTitle = styled(Base)`
  ${getTypeStyle('mainTitle')};
`

export const PageTitle = styled(Base).attrs({ as: 'h1' })`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-bottom: 1.5rem;
  ${getTypeStyle('mobileTitle')};
  ${above('lg')`
    ${getTypeStyle('mainTitle')};
  `}
`

export const SectionTitle = styled(Base).attrs({ as: 'h2' })`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-bottom: 1.5rem;
  ${getTypeStyle('mobileTitle')};
  ${above('lg')`
    margin-bottom: 1.25rem;
    ${getTypeStyle('title')};
  `}
`

export const SectionDescription = styled(Base)`
  max-width: 26em;
  margin-bottom: 1.5rem;
  ${getTypeStyle('smallText')};
  ${above('lg')`
    ${getTypeStyle('text')};
  `}
`
