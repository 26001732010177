import React from 'react'

const Youtube = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M19.464 14.625c-.031.553-.23 1.075-.594 1.566-.364.491-.8.783-1.307.875-3.392.245-7.1.245-11.126 0-.507-.062-.943-.346-1.307-.852-.365-.507-.563-1.036-.594-1.589a29.555 29.555 0 0 1 0-5.296c.031-.522.23-1.036.594-1.543.364-.506.8-.79 1.307-.852 3.297-.245 7.005-.245 11.126 0 .57.03 1.022.277 1.355.737.333.46.515.983.546 1.566a30.59 30.59 0 0 1 0 5.388zm-8.985.322L15.043 12l-4.564-2.947v5.894z'
    />
  </svg>
)

Youtube.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Youtube
