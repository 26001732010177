import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AspectRatio from '@/components/UI/AspectRatio/AspectRatio'
import Icon from '@/components/UI/Icon/Icon'
import {
  Wrapper,
  StyledImage,
  Bottom,
  Index,
  Title,
  ButtonPrev,
  ButtonNext,
} from './Gallery.css'

const NextArrow = props => {
  const { onClick, color } = props
  return (
    <ButtonNext color={color} onClick={onClick}>
      <Icon.Arrow />
    </ButtonNext>
  )
}

const PrevArrow = props => {
  const { onClick, color } = props
  return (
    <ButtonPrev color={color} onClick={onClick}>
      <Icon.Arrow direction='left' />
    </ButtonPrev>
  )
}
const Gallery = ({ gallery, color, ...props }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const length = gallery?.length || 0

  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow color={color} />,
    prevArrow: <PrevArrow color={color} />,
    beforeChange: (current, nextSlide) => setSlideIndex(nextSlide),
  }

  if (length === 0) {
    return null
  }

  if (length === 1) {
    return (
      <Wrapper {...props}>
        {gallery.map((item, index) => (
          <AspectRatio key={item?.title} ratio={4 / 3}>
            <StyledImage
              key={item}
              {...item}
              src={item?.image}
              tabIndex={index === slideIndex ? 0 : -1}
            />
          </AspectRatio>
        ))}
        <Bottom>
          <Title>{gallery?.[slideIndex]?.title}</Title>
        </Bottom>
      </Wrapper>
    )
  }

  return (
    <Wrapper {...props}>
      <Slider {...settings}>
        {gallery.map((item, index) => (
          <AspectRatio ratio={4 / 3}>
            <StyledImage
              key={item}
              {...item}
              src={item?.image}
              tabIndex={index === slideIndex ? 0 : -1}
            />
          </AspectRatio>
        ))}
      </Slider>
      <Bottom>
        <div>
          <Index>
            {slideIndex + 1}/{length}
          </Index>
          <Title>{gallery?.[slideIndex]?.title}</Title>
        </div>
      </Bottom>
    </Wrapper>
  )
}

Gallery.defaultProps = {
  color: 'maastricht',
}

export default Gallery
