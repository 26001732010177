import React, { cloneElement, Children } from 'react'
import { Portal } from 'reakit/Portal'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import { Backdrop, Wrapper, Inner } from './Modal.css'

const Modal = ({ handler, children, persist, ...props }) => {
  const state = useDialogState()
  return (
    <>
      {handler && (
        <DialogDisclosure {...state}>
          {handlerProps => cloneElement(Children.only(handler), handlerProps)}
        </DialogDisclosure>
      )}
      {(state.visible || persist) && (
        <>
          <Portal>
            <Backdrop {...state} />
          </Portal>
          <Wrapper {...state} {...props}>
            <Inner>{children}</Inner>
          </Wrapper>
        </>
      )}
    </>
  )
}

export default Modal
