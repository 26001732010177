import styled, { css } from 'styled-components'
import { Footnote } from '@/components/UI/Typography/Typography'

export const Wrapper = styled.label``

export const Field = styled.input`
  width: 100%;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  color: ${p =>
    p.color === 'white' ? p.theme.colors.white : p.theme.colors.black};
  display: block;
  font: inherit;
  padding: 0.5rem 0;
  border-bottom: ${p =>
    p.color === 'white'
      ? `1px solid ${p.theme.colors.gray}`
      : '1px solid currentColor'};
  border-radius: 0;
  resize: none;

  ${p =>
    p.default &&
    css`
      border-color: ${p.theme.colors.gunmetal};
    `}

  &::placeholder {
    color: currentColor;
    ${p =>
      p.default &&
      css`
        color: ${p.theme.colors.gunmetal};
      `}
    ${p =>
      p.color === 'white' &&
      css`
        color: ${p.theme.colors.gray};
      `}
  }
  &:focus {
    border-color: currentColor;
  }
  ${p =>
    p.icon &&
    `
    padding-right: 2rem;
  `}

  ${p =>
    p.as === 'select' &&
    css`
      color: ${p.theme.colors.blue};
      border-color: ${p.theme.colors.blue};
    `}
`

export const FieldWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 0.5rem;
  &:focus-within {
    color: ${p => p.theme.colors[p.color] || p.theme.colors.blue};
    ${Field}::placeholder {
      color: ${p => p.theme.colors[p.color] || p.theme.colors.maastricht};
      opacity: 0.4;
    }
  }
  ${p =>
    p.as === 'select' &&
    css`
      color: ${p.theme.colors.blue};
    `}
  ${p =>
    p.isForm &&
    css`
      padding: 0 0.5rem;
    `}
`

export const Absolute = styled.button`
  position: absolute;
  right: 12px;
  color: currentColor;
  ${p =>
    p.type === 'select' &&
    css`
      cursor: default;
      pointer-events: none;
      path {
        stroke: ${p.theme.colors.blue};
      }
    `}
`

export const LabelText = styled(Footnote)`
  min-height: 0.875rem;
  ${p =>
    p.hide &&
    `
    display: none;
  `}
`
