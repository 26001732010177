import React from 'react'
import styled from 'styled-components'

const getTransform = direction => {
  if (direction === 'down') return `rotate(90deg)`
  if (direction === 'left') return 'rotate(180deg)'
  if (direction === 'up') return 'rotate(270deg)'
  return '0'
}

const Wrapper = styled.svg`
  transform: ${p => getTransform(p.direction, p.size)};
  display: block;
`

const Arrow = ({ size, color, strokeWidth, ...props }) => (
  <Wrapper width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill='none'
      stroke={color}
      strokeWidth={strokeWidth}
      d='M4 12.5h15M14.773 7.75l4.773 4.773-4.773 4.773'
    />
  </Wrapper>
)

Arrow.defaultProps = {
  size: 24,
  strokeWidth: 1.2,
  color: 'currentColor',
}

export default Arrow
