import styled from 'styled-components'
import above from '@/utils/above'

export const Wrapper = styled.div`
  * {
    outline: none;
  }

  .slick-dots {
    display: flex !important;
    justify-content: flex-start;
    bottom: -45px;

    li {
      width: auto;
      height: auto;
      margin: 0;
    }
  }
  ${above('md')`
    .slick-list {
      overflow: visible;
    }
   `}
`

export const Button = styled.div`
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  ${p =>
    p.active &&
    `
    cursor: default;
  `}
`

export const Dot = styled.div`
  background: ${p => p.theme.colors.gunmetal};
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100%;
  font-size: 0;
  ${p =>
    p.active &&
    `
    background: ${p.theme.colors[p.color || 'maastricht']};
    transform: scale(1.35);
  `}
`
