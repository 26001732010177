import React from 'react'
import styled, { css } from 'styled-components'
import above from '@/utils/above'

import Image from './Image'

const Wrapper = styled.div`
  position: absolute;
  height: auto;
  z-index: 10;
  ${props =>
    props.hiddenMobile &&
    css`
      display: none;
      ${above('xg')`
        display: block;
    `}
    `};
  width: ${props => props.width};
  left: ${props => props.left};
  top: ${props => props.top};
`
const ImageLoose = ({ src, alt, width, left, top, hiddenMobile, ...props }) => (
  <Wrapper
    width={`${width}px`}
    left={left}
    top={top}
    hiddenMobile={hiddenMobile}
  >
    <Image src={src} alt={alt} {...props} />
  </Wrapper>
)

export default ImageLoose
