import styled from 'styled-components'
import { Dialog, DialogBackdrop } from 'reakit/Dialog'
import above from '@/utils/above'

export const Backdrop = styled(DialogBackdrop)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${p => p.theme.colors.maastricht}CC;
  cursor: pointer;
  z-index: 20;
`

export const Wrapper = styled(Dialog)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  z-index: 9999999999;
`

export const Inner = styled.div`
  pointer-events: all;
  background: ${p => p.theme.colors.white};
  border-radius: 0.25rem;
  overflow: hidden;
  max-width: 80rem;
  margin: 1rem;
  ${above('md')`
    margin: 2rem;
  `}
  ${above('lg')`
    margin: 2.5rem;
  `}
`
