import Arrow from './Arrow'
import Burguer from './Burguer'
import Chevron from './Chevron'
import Close from './Close'
import Document from './Document'
import Download from './Download'
import Facebook from './Facebook'
import Instagram from './Instagram'
import Linkedin from './Linkedin'
import PlayCircle from './PlayCircle'
import Plus from './Plus'
import Quote from './Quote'
import Scissors from './Scissors'
import Twitter from './Twitter'
import Youtube from './Youtube'

const Icon = {
  Arrow,
  Burguer,
  Chevron,
  Close,
  Document,
  Download,
  Facebook,
  Instagram,
  Linkedin,
  PlayCircle,
  Plus,
  Quote,
  Scissors,
  Twitter,
  Youtube,
}

export default Icon
