import styled, { css } from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import BaseGrid from '@/components/UI/Grid/Grid'
import getTypeStyle from '@/utils/getTypeStyle'
import Link from '@/components/UI/Link/Link'
import Image from '@/components/UI/Image/Image'
import { SmallText } from '@/components/UI/Typography/Typography'

export const Wrapper = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${p =>
    p.isPrefooter ? p.theme.colors.lightMaastricht : p.theme.colors.white};
  box-shadow: 0 0.25rem 2.5rem 0 ${p => p.theme.colors.maastricht}14;
  border-radius: 0.125rem;
  overflow: hidden;
  position: relative;
  transition: all ${p => p.theme.transitions.ease()};
  ${p =>
    p.noDescription &&
    css`
      min-height: 295px;
    `}

  ${p =>
    p.isPrefooter &&
    css`
      ${above('lg')`
      min-height: 392px;
    `}
      
      color: ${p.theme.colors.white};
    `}
  ${p =>
    p.isCourse &&
    css`
      ${SmallText} {
        color: ${p.theme.colors.gray};
      }
    `}
  @media not all and (pointer: coarse) {
    &:hover,
    &:active {
      background: ${p => p.theme.colors[p.color || 'maastricht']};
      color: ${p => p.theme.colors.white};
       ${SmallText} {
        color: ${p => p.theme.colors.white};
      }
    }
  }

  ${({ fixColorInMobile }) =>
    fixColorInMobile &&
    css`
      @media (max-width: 767px) {
        background: ${p => p.theme.colors[p.color]};
        color: ${p => p.theme.colors.white};
      }
    `}
`

export const Grid = styled(BaseGrid)`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${p =>
    p.imagePosition === 'left' &&
    css`
      ${above('md')`
      flex-direction: row-reverse;
    `}
    `}
`

export const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  ${p =>
    p.imagePosition &&
    css`
      ${above('md')`
      max-width: ${getColumnSize(5)};
    `}
      ${above('lg')`
      max-width: ${getColumnSize(4)};
    `}
    `}
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.isCourses ? 'space-between' : 'flex-start')};
  flex: 1;
  padding: 1.25rem 1.5rem 1.5rem;

  svg {
    margin-top: auto;
  }

  ${p =>
    p.isPrefooter &&
    css`
      ${above('md')`
     justify-content: center;
      text-align: center;
    `}
    `}
  ${p =>
    p.imagePosition &&
    css`
      ${above('md')`
      padding-${p.imagePosition}: 0 !important;
    `}
    `}
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${p =>
    p.imagePosition
      ? css`
    max-height: 10.25rem;
    ${above('sm')`
      max-height: 12rem;
    `}
    ${above('md')`
      max-height: 100% !important;
      position: absolute;
      padding-${p.imagePosition}: 0;
      height: 100%;
      ${p.imagePosition}: 0;
      width: ${getColumnSize(7)};
    `}
    ${above('lg')`
      width: ${getColumnSize(8)};
    `}
  `
      : css`
    max-height: 10.25rem;
    ${above('sm')`
      max-height: 12rem;
    `}
    ${above('md')`
      max-height: 15rem;
    `}
    ${above('lg')`
      max-height: 17.75rem;
    `}
  `}
  ${p =>
    p.type === 'article' &&
    `
    max-height: 10rem !important;
  `}
`

export const StyledImage = styled(Image)`
  display: block;
  object-fit: cover;
  object-position: center;
  min-height: 100%;
  max-height: 11rem;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  ${p =>
    p.type !== 'article' &&
    `
    max-height: auto;
    min-height: 11rem;
    ${above('md')`
      min-height: 17rem;
    `}
  `}
`

export const TextWrapper = styled.div`
  margin-bottom: ${p => (p.isCourses ? '0rem' : '2rem')};
`

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;

  margin-top: auto;
`

export const Tag = styled.li`
  display: flex;
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  ${getTypeStyle('label')};
  &:after {
    content: '•';
    margin: 0 0.5rem;
  }
  &:last-child {
    &:after {
      content: none;
    }
  }
  @media not all and (pointer: coarse) {
    ${Wrapper}:hover &,
    ${Wrapper}:active & {
      color: ${p => p.theme.colors.white};
      opacity: 0.8;
    }
  }
`

export const LabelMetricText = styled.p`
  ${getTypeStyle('label')};
  text-transform: uppercase;
`

export const DescriptionMetricText = styled.p`
  ${getTypeStyle('smallText')};
  margin-top: 6px;
  color: ${p => p.theme.colors.maastricht}56;
  ${Wrapper}:hover &,
  ${Wrapper}:active & {
    color: ${p => p.theme.colors.white};
  }
`

export const WrapperMetrics = styled.div`
  display: flex;
  align-items: ${p => (p.variant === 'column' ? 'flex-start' : 'center')};
  flex-direction: ${p => (p.variant === 'column' ? 'column' : 'row')};
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 12px 16px;
  background: ${p => p.theme.colors.gunmetal}12;
  ${Wrapper}:hover &,
  ${Wrapper}:active & {
    background: ${p => p.theme.colors.white}12;
  }
`

export const ValueMetricText = styled.p`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-right: 8px;
  ${getTypeStyle('caption')};
  ${Wrapper}:hover &,
  ${Wrapper}:active & {
    color: ${p => p.theme.colors.white};
  }
`

export const Title = styled.h3`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  ${getTypeStyle('caption')};
  ${p =>
    p.type !== 'article' &&
    `
    ${getTypeStyle('subtitle')};
  `}
  ${p =>
    p.type === 'prefooter' &&
    !p.isEventType &&
    css`
      ${above('md')`
        ${getTypeStyle('title')};
    `}
    `}
`

export const CardDate = styled.div`
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  ${getTypeStyle('label')};
  margin-bottom: 0.75rem;
  ${Wrapper}:hover &,
  ${Wrapper}:focus &,
  ${Wrapper}:active & {
    color: ${p => p.theme.colors.white};
    opacity: 0.8;
  }
  ${p =>
    p.isEventType &&
    css`
      font-family: ${p.theme.type.fonts.headline};
      color: ${p.theme.colors.white};
      ${getTypeStyle('title')};
    `}
`
