const colors = {
  darkBlue: '#3090F0',
  blue: '#3398FD',
  lightBlue: '#47A2FD',
  darkOrange: '#F26349',
  orange: '#FF694D',
  lightOrange: '#FF785F',
  darkYellow: '#F5B938',
  yellow: '#FFC13B',
  lightYellow: '#FACF4B',
  darkAqua: '#4DBAC1',
  aqua: '#52C4CC',
  lightAqua: '#63CAD1',
  maastricht: '#091C2E',
  lightMaastricht: '#122436',
  slate: '#77808C',
  gray: '#8D97A6',
  gunmetal: '#C0C7D1',
  darkFlash: '#E4E6EA',
  flash: '#F2F4F6',
  snow: '#FAFBFC',
  white: '#FFFFFF',
}

export default colors
