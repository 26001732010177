import React from 'react'

const Close = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path fill='none' stroke={color} d='M22 6L10 18M22 18L10 6' />
  </svg>
)

Close.defaultProps = {
  size: 24,
  color: 'currentColor',
  strokeWidth: 1.3,
  style: { display: 'block' },
}

export default Close
