import React from 'react'

const Instagram = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M16.278 5.583c.604 0 1.112.206 1.523.616.41.411.616.919.616 1.523v8.556c0 .604-.206 1.112-.616 1.523-.411.41-.919.616-1.523.616H7.722c-.604 0-1.112-.206-1.523-.616-.41-.411-.616-.919-.616-1.523V7.722c0-.604.206-1.112.616-1.523.411-.41.919-.616 1.523-.616h8.556zM13.64 10.36c-.442-.442-.989-.663-1.64-.663-.651 0-1.198.22-1.64.663-.442.442-.663.989-.663 1.64 0 .651.22 1.198.663 1.64.442.442.989.663 1.64.663.651 0 1.198-.22 1.64-.663.442-.442.663-.989.663-1.64 0-.651-.22-1.198-.663-1.64zm1.272-2.193c0 .27.086.492.258.663a.898.898 0 0 0 .663.258.861.861 0 0 0 .654-.285.91.91 0 0 0 .267-.636c0-.233-.095-.445-.285-.636-.19-.19-.403-.285-.636-.285a.91.91 0 0 0-.636.267.861.861 0 0 0-.285.654z'
    />
  </svg>
)

Instagram.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Instagram
