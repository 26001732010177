import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: ${p => p.gutter / -2}px;
  & > * {
    padding: ${p => p.gutter / 2}px;
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
      `}
  }
`

const Group = ({ children, gutter = 16, ...props }) => (
  <Wrapper gutter={gutter} {...props}>
    {React.Children.map(children, child => (
      <div>{child}</div>
    ))}
  </Wrapper>
)

export default Group
