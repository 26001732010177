import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'

export const Base = styled(TextareaAutosize)`
  font-size: 1rem;
  width: 100%;

  line-height: 1.5rem;
  padding: 0 0 0.5rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  bottom: 0;

  background: none;
  border: none;
  outline: none;
  appearance: none;
  color: ${p =>
    p.color === 'white' ? p.theme.colors.white : p.theme.colors.black};
  display: block;
  font: inherit;
  border-bottom: 1px solid ${p => p.theme.colors.gunmetal};
  border-radius: 0;

  margin-top: 1rem;

  ${p =>
    p.default &&
    css`
      border-color: ${p.theme.colors.gunmetal};
    `}

  &::placeholder {
    color: ${p => p.theme.colors.gunmetal};
    opacity: 0.8;
    ${p =>
      p.default &&
      css`
        color: ${p.theme.colors.gunmetal};
      `}
  }
  &:focus {
    border-color: currentColor;
  }
`

export const Wrapper = styled.div`
  &:focus-within {
    color: ${p => p.theme.colors[p.color] || p.theme.colors.blue};
    ${Base}::placeholder {
      color: ${p => p.theme.colors[p.color] || p.theme.colors.maastricht};
      opacity: 0.4;
    }
  }
`
