import styled, { css } from 'styled-components'
import getReadableColor from '@/utils/getReadableColor'
import getDarkerColor from '@/utils/getDarkerColor'
import Link from '@/components/UI/Link/Link'
import { SmallText } from '@/components/UI/Typography/Typography'
/* eslint-disable import/prefer-default-export */

const styleByAppearance = p => css`
  background: ${p.theme.colors[p.appearance]};
  color: ${p.theme.colors[getReadableColor(p.appearance)]};
  &:hover,
  &:active {
    background: ${p.theme.colors[getDarkerColor(p.appearance)]};
    color: ${p.theme.colors[getReadableColor(p.appearance)]};
  }
`

const ghostAppearance = p => css`
  opacity: 0.8;
  box-shadow: inset 0 0 0 1px currentColor;
  &:hover,
  &:active {
    opacity: 1;
    ${p.hasHoverRed &&
      css`
        background: ${p.theme.colors.darkOrange};
        color: ${p.theme.colors.white};
      `}
    ${p.hasHoverBlue &&
      css`
        background: ${p.theme.colors.blue};
        color: ${p.theme.colors.white};
      `}
  }
  &:disabled {
    opacity: 0.4;
  }
`

export const Wrapper = styled(Link)([
  p => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  border-radius: 0.125rem;
  transition: all ${p.theme.transitions.ease()};
  ${p.onClick && 'cursor: pointer;'}
  ${p.stretch && 'width: 100%;'}
  ${p.appearance === 'ghost' ? ghostAppearance : styleByAppearance}
  & ${SmallText} {
    position: relative;
    display: flex;
    align-items: center;
  }
  ${p.disabled &&
    `
    opacity: 0.4 !important;
    cursor: not-allowed !important;
  `}
  }
`,
])
