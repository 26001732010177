import { css } from 'styled-components'

export const tiemposText = css`
  @font-face {
    font-family: 'Tiempos Text';
    src: url('/fonts/tiempos-text/TiemposText-Regular.woff') format('woff'),
      url('/fonts/tiempos-text/TiemposText-Regular.ttf') format('true');
    font-style: normal;
    font-weight: 400;
  }
`

export const tiemposHeadline = css`
  @font-face {
    font-family: 'Tiempos Headline';
    src: url('/fonts/tiempos-headline/TiemposHeadline-Medium.woff2')
        format('woff2'),
      url('/fonts/tiempos-headline/TiemposHeadline-Medium.woff') format('woff'),
      url('/fonts/tiempos-headline/TiemposHeadline-Medium.otf')
        format('opentype');
    font-style: normal;
    font-weight: 500;
  }
`

export const roboto = css`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Roboto Regular '), local('Roboto-Regular'),
      url('/fonts/roboto/roboto-latin-400.woff2') format('woff2'),
      url('/fonts/roboto/roboto-latin-400.woff') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Roboto Medium '), local('Roboto-Medium'),
      url('/fonts/roboto/roboto-latin-500.woff2') format('woff2'),
      url('/fonts/roboto/roboto-latin-500.woff') format('woff');
  }
`
