import React from 'react'
import { SmallText } from '@/components/UI/Typography/Typography'
import {
  Wrapper,
  Grid,
  Inner,
  Main,
  TextWrapper,
  ImageWrapper,
  StyledImage,
  Tags,
  Tag,
  Title,
  CardDate,
  LabelMetricText,
  DescriptionMetricText,
  WrapperMetrics,
  ValueMetricText,
} from './Card.css'

const Card = ({
  to,
  color,
  image,
  title,
  description,
  tags,
  date,
  toDate,
  children,
  type,
  imagePosition,
  fixColorInMobile,
  isEventType,
  metricLabel,
  metricValue,
  metricDescription,
  isCourses,
  ...props
}) => (
  <Wrapper
    fixColorInMobile={fixColorInMobile}
    isCourse={type === 'course'}
    color={color}
    to={to}
    {...props}
    imagePosition={imagePosition}
  >
    <Grid imagePosition={imagePosition}>
      {image && (
        <ImageWrapper imagePosition={imagePosition} type={type}>
          <StyledImage
            type={type}
            alt={title}
            src={image}
            imagePosition={imagePosition}
          />
        </ImageWrapper>
      )}
      <Inner imagePosition={imagePosition}>
        <Main
          imagePosition={imagePosition}
          isPrefooter={props.isPrefooter}
          isCourses={isCourses}
        >
          {date && <CardDate isEventType={isEventType}>{date}</CardDate>}
          {(title || description) && (
            <TextWrapper isCourses={isCourses}>
              {title && (
                <Title isEventType={isEventType} type={type}>
                  {title}
                </Title>
              )}
              {description && <SmallText type={type}>{description}</SmallText>}
            </TextWrapper>
          )}
          {isCourses ? (
            <div>
              {(metricDescription || metricLabel || metricValue) && (
                <WrapperMetrics variant={metricValue ? 'row' : 'column'}>
                  {metricLabel && (
                    <LabelMetricText>{metricLabel}</LabelMetricText>
                  )}
                  {metricValue && (
                    <ValueMetricText>{metricValue}</ValueMetricText>
                  )}
                  {metricDescription && (
                    <DescriptionMetricText>
                      {metricDescription}
                    </DescriptionMetricText>
                  )}
                </WrapperMetrics>
              )}
              {!!tags?.length && (
                <Tags>
                  {tags.map(({ tag }) => (
                    <Tag>{tag}</Tag>
                  ))}
                </Tags>
              )}
            </div>
          ) : (
            <>
              {!!tags?.length && (
                <Tags>
                  {tags.map(({ tag }) => (
                    <Tag>{tag}</Tag>
                  ))}
                </Tags>
              )}
            </>
          )}
          {children}
        </Main>
      </Inner>
    </Grid>
  </Wrapper>
)

export default Card
