import React from 'react'
import Icon from '@/components/UI/Icon/Icon'
import Profile from '@/components/UI/Profile/Profile'
import Modal from '@/components/UI/Modal/Modal'
import Youtube from '@/components/UI/Youtube/Youtube'
import {
  Wrapper,
  Bottom,
  Separator,
  Content,
  Handler,
  InterviewText,
} from './Testimonial.css'

const Testimonial = ({
  color,
  name,
  position,
  src,
  content,
  interview_text,
  interview_link,
  ...props
}) => (
  <Wrapper color={color} {...props}>
    <Icon.Quote />
    {content?.html && (
      <Content as='div' dangerouslySetInnerHTML={{ __html: content.html }} />
    )}
    <Bottom>
      {name && <Profile name={name} position={position} src={src} />}
      {name && interview_text && <Separator />}
      {interview_text && interview_link && (
        <Modal
          handler={
            <Handler>
              <Icon.PlayCircle />
              <InterviewText>{interview_text}</InterviewText>
            </Handler>
          }
        >
          <Youtube id={interview_link} />
        </Modal>
      )}
    </Bottom>
  </Wrapper>
)

export default Testimonial
