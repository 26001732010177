import React from 'react'

const Linkedin = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M9.169 16.636H6.797V9.04H9.17v7.596zm-1.21-8.622c-.363 0-.656-.119-.877-.356a1.23 1.23 0 0 1-.332-.872c0-.343.115-.63.344-.859.23-.23.533-.344.913-.344s.676.115.889.344c.213.23.328.516.344.86 0 .343-.115.634-.344.87-.23.238-.541.357-.937.357zm9.817 8.622H15.43v-4.225c0-.49-.095-.886-.285-1.187-.19-.3-.498-.451-.924-.451-.317 0-.574.095-.771.285-.198.19-.344.388-.439.593a.925.925 0 0 0-.06.273c-.007.103-.011.21-.011.32v4.392h-2.371v-5.175c0-.474-.004-.913-.012-1.317a39.78 39.78 0 0 0-.036-1.104h2.04l.118 1.045h.048c.158-.254.426-.523.806-.807.38-.285.893-.428 1.541-.428.775 0 1.419.27 1.933.807.514.538.77 1.37.77 2.493v4.486z'
    />
  </svg>
)

Linkedin.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Linkedin
