import React from 'react'

const Document = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <g fill='none' fillRule='evenodd' stroke={color}>
      <path strokeLinejoin='round' d='M6.5 5v14h11V9L13.5 5z' />
      <path strokeLinejoin='round' d='M13 5v4h4.5' />
      <path d='M9 12.5h6M9 15.5h6' />
    </g>
  </svg>
)

Document.defaultProps = {
  size: 24,
  color: 'currentColor',
  strokeWidth: 1,
  style: { display: 'block' },
}

export default Document
