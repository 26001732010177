import { css } from 'styled-components'
import { stringify, parse } from './dimensions'

// Use a string to generate media query based on breakpoints keys
const above = label => (...args) => ({ theme }) => css`
  @media (min-width: ${stringify(
      parse(theme?.grid?.breakpoints?.[label]?.width || label),
    )}) {
    ${css(...args)}
  }
`

export default above
