import capitalize from '@/utils/capitalize'

const getDarkerColor = color => {
  if (color.includes('light')) {
    return color.replace('light', '').toLowerCase()
  }
  if (color.includes('dark')) {
    return color
  }
  return `dark${capitalize(color)}`
}

export default getDarkerColor
