import React from 'react'
import getDate from '@/utils/getDate'
import {
  Wrapper,
  Grid,
  Inner,
  Main,
  CardDate,
  Kind,
  Title,
  Location,
  ImageWrapper,
  Image,
} from './EventCard.css'

const EventCard = ({
  to,
  color,
  background,
  date,
  toDate,
  kind,
  title,
  location,
  image,
  imagePosition,
  fullWidth,
  ...props
}) => {
  const toDateFormated = getDate(toDate)
  return (
    <Wrapper
      color={color}
      background={background}
      imagePosition
      to={to}
      {...props}
    >
      <Grid imagePosition={imagePosition} fullWidth={fullWidth}>
        <Inner imagePosition={imagePosition}>
          <Main imagePosition={imagePosition}>
            <CardDate>{date}</CardDate>
            {toDateFormated ? <CardDate> - {toDateFormated}</CardDate> : null}
            <Kind>{kind}</Kind>
            <Title>{title}</Title>
            <Location>{location}</Location>
          </Main>
        </Inner>
        <ImageWrapper imagePosition={imagePosition}>
          <Image src={image} />
        </ImageWrapper>
      </Grid>
    </Wrapper>
  )
}

EventCard.defaultProps = {
  color: 'maastricht',
  background: 'white',
}

export default EventCard
