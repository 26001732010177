import React from 'react'
import Icon from '@/components/UI/Icon/Icon'
import {
  Wrapper,
  Main,
  StyledImage,
  Name,
  Position,
  Bottom,
  Social,
  SocialLink,
  SocialCircle,
  Button,
} from './Member.css'

const Each = ({ icon, ...props }) => (
  <SocialLink {...props}>
    <SocialCircle>{icon}</SocialCircle>
  </SocialLink>
)

const SocialBar = ({ twitter, linkedin, ...props }) => (
  <Social {...props}>
    {twitter?.url && <Each to={twitter.url} icon={<Icon.Twitter />} />}
    {linkedin?.url && <Each to={linkedin.url} icon={<Icon.Linkedin />} />}
  </Social>
)

const Member = ({ image, name, position, twitter, linkedin, cv, download }) => (
  <Wrapper>
    <StyledImage src={image} />
    <Main>
      <Name>{name}</Name>
      <Position>{position}</Position>
      {(twitter || linkedin || cv) && (
        <Bottom>
          <div>
            <SocialBar twitter={twitter} linkedin={linkedin} />
          </div>
          <div>
            {cv && (
              <Button to={cv.url}>
                <Icon.Document />
                {download}
              </Button>
            )}
          </div>
        </Bottom>
      )}
    </Main>
  </Wrapper>
)

export default Member
