import React from 'react'

const Plus = ({ size, color, strokeWidth, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill='none'
      stroke={color}
      strokeLinecap='square'
      strokeWidth={strokeWidth}
      d='M12 5v14M5 12h14'
    />
  </svg>
)

Plus.defaultProps = {
  size: 24,
  strokeWidth: 1,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Plus
