import React from 'react'
// import Base from '@/components/UI/Input/Input'
import { Wrapper, Base } from './TextArea.css'

const TextArea = props => (
  <Wrapper>
    <Base {...props} />
  </Wrapper>
)

export default TextArea
