import { createGlobalStyle } from 'styled-components'
import reset from 'wipe.css'
import { tiemposHeadline, tiemposText, roboto } from './fonts.css'

const GlobalStyle = createGlobalStyle`
	${reset}
	${roboto}
	${tiemposHeadline}
	${tiemposText}
	${({ background, color, theme }) => `
		html, body {
			font-family: ${theme.type.fonts.roboto};
			background: ${theme.colors[background] || theme.colors.snow};
			color: ${theme.colors[color] || theme.colors.maastricht};
			text-rendering: optimizeLegibility;
			font-smooth: antialised;
			font-smoothing: antialised;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 0, 'tnum' 1, 'onum' 0, 'lnum' 1, 'dlig' 1, 'zero' 1, 'case' 1;
			height: auto;
			min-height: 100vh;
			overflow-x: hidden!important;
		}

		::selection{
			color: ${theme.colors[background] || theme.colors.snow};
			background: ${theme.colors[color] || theme.colors.maastricht};
		}
		#__gatsby{
			min-height: 100%;
			display: flex;
			flex-direction: column;
		}
	`}
`

export default GlobalStyle
