import React from 'react'
import Icon from '@/components/UI/Icon/Icon'
import { Wrapper, Link, Circle } from './SocialBar.css'

const Each = ({ icon, ...props }) => (
  <Link {...props}>
    <Circle>{icon}</Circle>
  </Link>
)

const SocialBar = ({
  facebook,
  twitter,
  instagram,
  linkedin,
  youtube,
  ...props
}) => (
  <Wrapper {...props}>
    {facebook?.url && <Each to={facebook.url} icon={<Icon.Facebook />} />}
    {twitter?.url && <Each to={twitter.url} icon={<Icon.Twitter />} />}
    {instagram?.url && <Each to={instagram.url} icon={<Icon.Instagram />} />}
    {linkedin?.url && <Each to={linkedin.url} icon={<Icon.Linkedin />} />}
    {youtube?.url && <Each to={youtube.url} icon={<Icon.Youtube />} />}
  </Wrapper>
)

export default SocialBar
