import React from 'react'

const Quote = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M17.63208 10.90135h2.4198c2.1651.45263 3.82076 1.93983 3.82076 4.6556 0 2.9744-2.22877 4.91423-5.09434 4.91423-3.43868 0-5.5401-2.78043-5.5401-6.72475C13.2382 7.8623 16.99529 3.98263 24 3.53v2.13381c-3.82075.51729-6.04953 2.13381-6.36792 5.23754zm-13.23821 0h2.48349c2.10141.45263 3.75707 1.93983 3.75707 4.6556 0 2.9744-2.22877 4.91423-5.09434 4.91423C2.10142 20.47118 0 17.69075 0 13.74643 0 7.8623 3.82075 3.98263 10.82547 3.53v2.13381c-3.82075.51729-6.1132 2.13381-6.4316 5.23754z'
    />
  </svg>
)

Quote.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Quote
