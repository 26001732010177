import React from 'react'
import Image from '@/components/UI/Image/Image'
import { SmallText } from '@/components/UI/Typography/Typography'
import { Wrapper, Avatar } from './Profile.css'

const Profile = ({ name, position, src, size, ...props }) => (
  <Wrapper {...props}>
    {src.localFile && (
      <Avatar size={size}>
        <Image alt={name} src={src} />
      </Avatar>
    )}
    <div>
      {name && <SmallText color='maastricht'>{name}</SmallText>}
      {position && <SmallText color='gray'>{position}</SmallText>}
    </div>
  </Wrapper>
)

export default Profile
