import React from 'react'

const Burguer = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path fill='none' stroke={color} d='M24 16H8M24 8H8' />
  </svg>
)

Burguer.defaultProps = {
  size: 24,
  color: 'currentColor',
  strokeWidth: 1.3,
  style: { display: 'block' },
}

export default Burguer
