import React from 'react'

const Twitter = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' {...props}>
    <path
      fill={color}
      d='M18.002 8.817c.033.137.05.274.05.411 0 2.327-.807 4.44-2.42 6.34-1.615 1.899-3.772 2.849-6.473 2.849-1.712 0-3.293-.48-4.742-1.438.23.035.477.052.74.052 1.417 0 2.701-.462 3.854-1.386a3.154 3.154 0 0 1-1.828-.668 3.117 3.117 0 0 1-1.087-1.591 3.468 3.468 0 0 0 1.433-.051 3.24 3.24 0 0 1-1.803-1.13c-.478-.581-.716-1.266-.716-2.053v-.051c.428.273.905.41 1.432.41-.955-.65-1.432-1.54-1.432-2.669 0-.582.148-1.13.444-1.643 1.647 2.122 3.788 3.251 6.422 3.388a2.894 2.894 0 0 1-.098-.77c0-.89.304-1.65.914-2.284.609-.633 1.342-.95 2.198-.95.922 0 1.68.343 2.273 1.027a5.582 5.582 0 0 0 1.976-.77c-.23.787-.675 1.386-1.334 1.797a5.824 5.824 0 0 0 1.778-.514 6.9 6.9 0 0 1-1.58 1.694z'
    />
  </svg>
)

Twitter.defaultProps = {
  size: 24,
  color: 'currentColor',
  style: { display: 'block' },
}

export default Twitter
