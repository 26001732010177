// eslint-disable-next-line import/no-unresolved
import React from 'react'

import { ThemeProvider } from '@/theme/theme'
import SEO from '@/components/SEO/SEO'
import Style from './layout.css'

const Layout = ({ children, title, image, description, background, color }) => {
  const meta = {
    title,
    description,
    image,
  }
  return (
    <ThemeProvider>
      <>
        <SEO {...meta} />
        <Style background={background} color={color} />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Layout
