import React from 'react'
import Icon from '@/components/UI/Icon/Icon'
import { Wrapper, Text } from './ArrowLink.css'

const ArrowLink = ({ children, color, reverse, ...props }) => (
  <Wrapper color={color} {...props} reverse={reverse}>
    {reverse && <Icon.Arrow direction='left' />}
    <Text reverse={reverse}>{children}</Text>
    {!reverse && <Icon.Arrow />}
  </Wrapper>
)

export default ArrowLink
